<template>
  <div class="d-flex justify-content-center custom-width">
    <div :key="state">
      <div id="header" ref="header">
        <div class="d-flex flex-row justify-content-around logo">
          <img
            src="@/assets/skl-logo.png"
            alt="Smiley face"
            height="27"
            width="70"
            class="mb-2"
          />
        </div>
        <div
          id="header-title"
          class="d-flex flex-row justify-content-around text-center title"
        >
          <b>{{ pageTitle }}</b>
        </div>
      </div>
      <div class="main-container p-4" ref="body">
        <div
          id="content-register"
          v-if="state == 'content-register'"
          :class="[
            'd-flex',
            'align-items-center',
            'justify-content-between',
            'flex-column',
            ''
          ]"
        >
          <div class="register-box">
            <p class="text-center font-weight-bold f-18">กรุณากรอกข้อมูล</p>
            <p class="text-center">สำหรับลูกค้าของสยามคูโบต้า ลีสซิ่ง</p>
            <p class="mt-2">ประเภทลูกค้า</p>
            <div class="d-flex justify-content-around mt-1 mb-4">
              <div
                id="CustomerBtn"
                :class="[
                  'text-center',
                  'btn-liff',
                  'btn-liff-primary',
                  'mr-1',
                  { selected: form.typeAccount == 1 }
                ]"
                @click="clickCustomerBtn"
              >
             
                <svg
                  id="CustomerBtn-icon"
                  class="svg-btn"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="32"
                  viewBox="0 0 24 32"
                >
                  <g
                    id="user-black-close-up-shape"
                    transform="translate(-55.455)"
                  >
                    <path
                      id="Path_8811"
                      data-name="Path 8811"
                      d="M71.253,13.171A7.17,7.17,0,0,0,74.5,7.154a7.041,7.041,0,1,0-14.08,0,7.169,7.169,0,0,0,3.243,6.017,9.114,9.114,0,0,0-8.2,9.12v7.425l.019.116.5.16A40.447,40.447,0,0,0,68.233,32c6.624,0,10.464-1.92,10.7-2.042l.47-.243h.049V22.291A9.113,9.113,0,0,0,71.253,13.171Z"
                      transform="translate(0 0)"
                    />
                  </g>
                </svg>
                <div>บุคคลทั่วไป</div>
              </div>
              <div
                id="CorporationBtn"
                :class="[
                  'text-center',
                  'btn-liff',
                  'btn-liff-primary',
                  'ml-1',
                  { selected: form.typeAccount == 2 }
                ]"
                @click="clickCorporationBtn"
              >
               
                <svg
                  id="CorporationBtn-icon"
                  class="svg-btn"
                  xmlns="http://www.w3.org/2000/svg"
                  width="26"
                  height="32"
                  viewBox="0 0 26 32"
                >
                  <path
                    id="building"
                    d="M70.942,29.935H68.911V4.645a1.031,1.031,0,0,0-1.03-1.032H60.159V1.032A1.031,1.031,0,0,0,59.129,0H51.985a1.031,1.031,0,0,0-1.03,1.032V3.613h-.837a1.031,1.031,0,0,0-1.03,1.032v25.29H47.059A1.05,1.05,0,0,0,46,30.918,1.031,1.031,0,0,0,47.03,32h7.337a.516.516,0,0,0,.515-.516V25.548H54.46a1.05,1.05,0,0,1-1.058-.982,1.031,1.031,0,0,1,1.029-1.082h9.11a1.05,1.05,0,0,1,1.058.982,1.031,1.031,0,0,1-1.029,1.082h-.45v5.935a.516.516,0,0,0,.515.516H70.97A1.031,1.031,0,0,0,72,30.918,1.05,1.05,0,0,0,70.942,29.935ZM56.49,21.355H55.332a1.032,1.032,0,0,1,0-2.065H56.49a1.032,1.032,0,0,1,0,2.065Zm0-3.871H55.332a1.032,1.032,0,0,1,0-2.065H56.49a1.032,1.032,0,0,1,0,2.065Zm0-3.871H55.332a1.032,1.032,0,0,1,0-2.065H56.49a1.032,1.032,0,0,1,0,2.065Zm0-3.871H55.332a1.032,1.032,0,0,1,0-2.065H56.49a1.032,1.032,0,0,1,0,2.065ZM62.6,21.355H61.446a1.032,1.032,0,0,1,0-2.065H62.6a1.032,1.032,0,0,1,0,2.065Zm0-3.871H61.446a1.032,1.032,0,0,1,0-2.065H62.6a1.032,1.032,0,0,1,0,2.065Zm0-3.871H61.446a1.032,1.032,0,0,1,0-2.065H62.6a1.032,1.032,0,0,1,0,2.065Zm0-3.871H61.446a1.032,1.032,0,0,1,0-2.065H62.6a1.032,1.032,0,0,1,0,2.065ZM56.941,31.484V26.065a.516.516,0,0,1,.515-.516h3.089a.516.516,0,0,1,.515.516v5.419a.516.516,0,0,1-.515.516H57.456A.516.516,0,0,1,56.941,31.484Z"
                    transform="translate(-46)"
                    fill="#80c141"
                  />
                </svg>
                <div>นิติบุคคล</div>
              </div>
            </div>
            <div class>
              <InputTextBox
                id="toggle-input-2"
                v-model="form.taxId"
                :placeholder="registerState.input_1.placeholder"
                type="text"
                @input="formatTaxId"
                :maxlength="17"
                pattern="[0-9]*"
                inputmode="numeric"
                inputChecker="number"
                required
                :isError="registerState.input_1.isError"
                :textFloat="registerState.input_1.title"
              />

              <InputSelectDatePicker
                textFloat="วัน/เดือน/ปีเกิด"
                isRequired
                v-model="form.birthDay"
                @handleSelect="val => (form.birthDay = val)"
              />
            </div>

            <div
              id="missing-input-error"
              class="text-center"
              v-if="registerState.error"
            >
              <template v-if="registerState.noData">
                <font color="red">ไม่พบข้อมูล<br /></font>
              </template>
              <template v-if="registerState.validateError">
                <font color="red"
                  >{{ registerState.validateMessage }}<br
                /></font>
              </template>
              <template v-if="registerState.internetError">
                <font color="red">
                  อินเตอร์เน็ตมีปัญหา กรุณาลองใหม่อีกครั้ง<br />
                </font>
              </template>
              <template
                v-if="
                  !registerState.validateError && !registerState.internetError
                "
              >
                <font color="red">กรุณาตรวจสอบความถูกต้องอีกครั้ง</font>
              </template>
              <template v-if="failCount >= 10">
                <b-button variant="link" @click="state = 'error'"
                  >Detail</b-button
                >
              </template>
            </div>
            <div class="d-flex justify-content-center">
              <b-button
                class="mt-2 btn-submit px-3"
                id="submit-register "
                @click="submitRegister"
                :disabled="buttonLoading || !form.birthDay || !form.taxId"
              >
                <b-spinner small v-if="buttonLoading"></b-spinner>
                <span v-else>ยืนยันข้อมูล</span>
              </b-button>
            </div>
          </div>
          <!-- <div id="debug" class="">
            <font color="red">debug</font>
        </div>-->
        </div>
        <div
          id="content-OTP"
          v-if="state == 'content-OTP'"
          :class="[
            'd-flex',
            'align-items-center',
            'justify-content-between',
            'flex-column',
            ''
          ]"
        >
          <div class="text-center">
            <FormOTP
              id="put-OTP"
              v-model="form.otpNumber"
              placeholder="ํOTP"
              type="number"
              pattern="\d*"
              inputmode="numeric"
              :isError="otpState.isError"
              MobileNumber="0867011096"
              prefix="otpState.prefix"
              @sendOTPAgain="sendOTP"
            />
            <div id="otp-error" class="text-center mt-3 f-14 w-100">
              <font color="red">{{ otpState.otpMessage }}</font>
              <template v-if="failCount >= 10">
                <b-button variant="link" @click="state = 'error'"
                  >Detail</b-button
                >
              </template>
            </div>

            <b-button
              id="submit-OTP"
              variant="submit"
              class="btn-submit w-100 mt-4"
              @click="submitOTP"
              :disabled="otpState.waiting"
              >ยืนยัน</b-button
            >
          </div>
        </div>

        <div v-if="state == 'change-line'">
          <div class="sub-container">
            <ChangeLinePage
              :displayName="displayName"
              @handleChangeLine="handleChangeLine"
            ></ChangeLinePage>
          </div>
        </div>
        <div
          id="content-policy"
          v-else-if="state == 'content-policy'"
          class="d-flex align-items-center justify-content-between flex-column"
          ref="policyContent"
        >
          <div
            id="policy"
            ref="policy"
            class="policy"
            @scroll="policyonScroll"
          ></div>
          <div
            class="d-flex flex-column align-items-center policy-bottom-area"
            ref="policyBtnArea"
          >
            <div id="policy-submit-area">
              <div
                id="checkbox-check"
                class="d-flex flex-row checkbox-area align-items-center"
                @click="clickPolicyCheckBox"
              >
                <div class="checkbox">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="23"
                    height="23"
                    viewBox="0 0 23 23"
                  >
                    <g
                      id="Rectangle_1784"
                      data-name="Rectangle 1784"
                      fill="#fff"
                      stroke="#707070"
                      stroke-width="1"
                    >
                      <rect width="23" height="23" rx="3" stroke="none" />
                      <rect
                        x="0.5"
                        y="0.5"
                        width="22"
                        height="22"
                        rx="2.5"
                        fill="none"
                      />
                    </g>
                    <path
                      v-if="policyState.seleced"
                      id="Path_8841"
                      data-name="Path 8841"
                      d="M-18087.189-17236.355l5.17,5.17,6.658-11"
                      transform="translate(18093 17248)"
                      fill="none"
                      stroke="#80c141"
                      stroke-linecap="round"
                      stroke-width="2"
                    />
                  </svg>
                </div>
                <div class="f-small-gray">
                  ยอมรับข้อกำหนดและเงื่อนไขการใช้บริการ
                </div>
              </div>
              <b-button
                class="mt-2 w-100 submit-policy"
                id="submit-policy"
                :disabled="!policyState.seleced || buttonLoading"
                variant="submit"
                @click="submitPolicy"
              >
                <b-spinner small v-if="buttonLoading"></b-spinner>
                <span v-else>ยินยอม</span>
              </b-button>
              <b-modal id="comfirmPolicy" hide-footer>
                <template v-slot:modal-title>
                  ยอมรับข้อกำหนดและเงื่อนไขการใช้บริการ
                </template>
                <div class="d-block text-center">
                  คุณได้อ่านและยอมรับข้อกำหนดและเงื่อนไขการใช้บริการ
                </div>
                <b-button
                  class="mt-3"
                  block
                  @click="
                    policyState.seleced = !policyState.seleced;
                    policyState.showCheckBox = true;
                    $bvModal.hide('comfirmPolicy');
                  "
                  >ยอมรับ</b-button
                >
                <b-button
                  class="mt-3"
                  block
                  @click="$bvModal.hide('comfirmPolicy')"
                  >ยกเลิก</b-button
                >
              </b-modal>
            </div>
          </div>
        </div>
        <div
          id="content-result"
          v-if="state == 'content-result'"
          :class="[
            'd-flex',
            'align-items-center',
            'justify-content-between',
            'flex-column',
            ''
          ]"
        >
          <div
            class="d-flex align-items-center justify-content-between flex-column h-100"
          >
            <b-img
              :src="lineProfile.pictureUrl"
              class="img-header-profile mt-4"
            />
            <div>{{ lineProfile.displayName }}</div>
            <div
              class="text-center f-16 font-weight-bold mt-4"
              v-if="isHaveLine"
            >
              ท่านโอนบัญชีไลน์ที่ใช้งานบริการ SKL คอนเนกต์เรียบร้อยแล้ว
            </div>
            <div class="text-center f-16 font-weight-bold mt-4" v-else>
              ท่านลงทะเบียนใช้งานบริการ SKL คอนเนกต์เรียบร้อยแล้ว
            </div>
            <b-button
              class="btn-submit w-100 mt-4"
              @click="$router.push('/installment')"
            >
              เริ่มต้นใช้งาน
            </b-button>
          </div>
        </div>

        <ErrorPage
          v-else-if="state == 'error'"
          :lineProfile="lineProfile"
          :form="form"
        >
        </ErrorPage>
        <div class="my-4" v-if="state !== 'content-policy'">
          <p class="txt-tel text-center f-12">
            สอบถามข้อมูลเพิ่มเติมโทร.
            <a
              ><span
                v-for="(c, i) in '1317'"
                :key="i"
                class="link-tel"
                :class="[{ 'link-tel-green': c == '3' }, 'link-tel']"
                >{{ c }}<span class="link-tel-green"></span></span
            ></a>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InputSelectDatePicker from "../../components/inputs/InputSelectDatePicker.vue";
import ChangeLinePage from "./components/ChangeLinePage.vue";

const InputTextBox = () => import("@/components/inputs/InputTextBox-New");
const FormOTP = () => import("@/components/panels/formOTP");
const ErrorPage = () => import("@/components/ErrorPage.vue");

export default {
  components: {
    ChangeLinePage,
    InputTextBox,
    InputSelectDatePicker,
    FormOTP,
    ErrorPage
  },
  data: () => ({
    lineProfile: {
      userId: "",
      displayName: "",
      pictureUrl: "",
      statusMessage: ""
    },
    displayName: "",
    pageTitle: "ลงทะเบียนใช้บริการ",
    buttonLoading: false,

    form: {
      typeAccount: 1,
      taxId: "",
      birthDay: "",
      otpNumber: ""
    },
    customerProfile: {
      IDCard: null,
      birthDay: null,
      CustomerName: null,
      MobileNumber: null,
      createdBy: 1640100289081,
      type: 0,
      displayName: null,
      term: false
    },
    debug: "",
    state: "content-register",
    // state: "change-line",
    failCount: 0,
    registerState: {
      error: false,
      noData: false,
      validateError: false,
      validateMessage: "",
      input_1: {
        title: "เลขบัตรประจำตัวประชาชน",
        placeholder: "เลขบัตรประจำตัวประชาชน 13 หลัก",
        isError: false
      },
      input_2: {
        title: "เลขที่สัญญา (4 ตัวสุดท้าย)",
        placeholder: "เลขที่สัญญา (4 ตัวสุดท้าย)",
        isError: false
      },
      input_3: {
        title: "วัน / เดือน / ปี เกิด",
        placeholder: "วัน / เดือน / ปี เกิด",
        isError: false
      }
    },
    otpState: {
      isError: false,
      otpMessage: "",
      prefix: "",
      waiting: false
    },
    isHaveLine: false,
    policyState: {
      showCheckBox: false,
      seleced: false
    },
    resultState: {
      result: "waiting"
    },
    termText: ""
  }),
  watch: {
    form: {
      deep: true,
      handler(form) {
        if (form.typeAccount == 1) {
          this.registerState.input_1 = {
            title: "เลขบัตรประจำตัวประชาชน",
            placeholder: "เลขบัตรประจำตัวประชาชน 13 หลัก"
          };
          this.registerState.input_2 = {
            title: "เลขที่สัญญา (4 ตัวสุดท้าย)",
            placeholder: "เลขที่สัญญา (4 ตัวสุดท้าย)"
          };
          this.registerState.input_3 = {
            title: "วัน / เดือน / ปี เกิด",
            placeholder: "วัน / เดือน / ปี เกิด"
          };
        }
        if (form.typeAccount == 2) {
          this.registerState.input_1 = {
            title: "เลขทะเบียนนิติบุคคล",
            placeholder: "เลขทะเบียนนิติบุคคล"
          };
          this.registerState.input_2 = {
            title: "เลขที่สัญญา (4 ตัวสุดท้าย)",
            placeholder: "เลขที่สัญญา (4 ตัวสุดท้าย)"
          };
          this.registerState.input_3 = {
            title: "วัน / เดือน / ปี ที่จดทะเบียน",
            placeholder: "วัน / เดือน / ปี ที่จดทะเบียน"
          };
        }
      }
    },
    state: {
      immediate: true,
      handler(state) {
        if (state == "content-policy") {
          this.$nextTick(() => {
            this.$refs.policy.style.height = `calc(100vh - ${this.$refs.header.clientHeight}px - ${this.$refs.policyBtnArea.clientHeight}px - 50px)`;

            this.$refs.body.style.height = `calc(100vh - ${this.$refs.header.clientHeight}px  )`;
          });
        }
      }
    }
  },
  beforeCreate() {
    let liffId = "";
    console.log(window.location.hostname);
    if (window.location.hostname !== "dev-line-eservice.skl.co.th") {
      liffId = "1654133345-xG7kaNvB";
    } else liffId = this.$liff_ID_Register;
    this.$liff
      .init({ liffId: liffId })
      .then(() => {
        if (!this.$liff.isLoggedIn()) {
          this.$liff.login().then(() => {
            this.$liff.getProfile().then(profile => {
              this.lineProfile = profile;
              console.log(this.lineProfile);

              // this.checkCustomer();
            });
          });
        } else {
          this.$liff.getProfile().then(profile => {
            this.lineProfile = profile;
            console.log(this.lineProfile);

            // this.checkCustomer();
          });
        }
        this.$liff.getFriendship().then(data => {
          if (!data.friendFlag) {
            this.$liff.openWindow({
              url: `https://line.me/R/ti/p/${this.$liff_Channel_ID}`,
              external: false
            });
            this.$liff.closeWindow();
          }
        });
      })
      .catch(error => {
        console.error(error);
      });
  },
  created() {
    this.clickCustomerBtn();
  },
  mounted() {},
  methods: {
    checkConsentRegister() {
      var requestOptions = {
        method: "GET",
        redirect: "follow"
      };
      fetch(
        `${this.$API_DEV_LINE_SKL}/Customer/TermsAndConditions`,
        requestOptions
      )
        .then(response => response.json())
        .then(result => {
          if (result.result == 1) {
            document.getElementById("policy").innerHTML = result.detail.content;
          }
        })
        .catch(error => console.log("error", error));
    },
    checkCustomer() {
      var requestOptions = {
        method: "GET",
        redirect: "follow"
      };

      fetch(
        `${this.$API_DEV_LINE_SKL}/customer/${this.lineProfile.userId}`,
        requestOptions
      )
        .then(response => response.json())
        .then(result => {
          if (result.result == 1) {
            document.getElementById(
              "content-register"
            ).innerHTML = `<div class="content_center">คุณเป็นสมาชิกแล้ว</div>`;
          }
          this.$isLoadingAlpha = false;
        })
        .catch(error => console.log("error", error));
    },
    closeWindow() {
      this.$liff.closeWindow();
    },
    clickCustomerBtn() {
      this.form.typeAccount = 1;
    },
    clickCorporationBtn() {
      this.form.typeAccount = 2;
    },
    submitRegister() {
      let taxId = this.form.taxId.replace(/\D/g, "");
      this.registerState.error = false;
      this.registerState.noData = false;
      this.registerState.validate = false;
      this.registerState.internetError = false;
      this.registerState.input_1.isError = false;
      this.registerState.input_3.isError = false;
      if (this.form.typeAccount == 1 && !/^[0-9]{13}$/.test(taxId)) {
        this.registerState.input_1.isError = true;
        this.registerState.error = true;
      }
      if (!this.form.birthDay) {
        this.registerState.input_3.isError = true;
        this.registerState.error = true;
      }
      if (
        (this.form.typeAccount == 2 || /^[0-9]{13}$/.test(taxId)) &&
        !/^[0-9]{4}\/[0-9]{2}\/[0-9]{2}$/.test(this.form.birthDay)
      ) {
        this.buttonLoading = true;
        var data = {
          CustomerType: "0" + this.form.typeAccount,
          IDCard: taxId,
          Birthdate: this.form.birthDay
        };

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        var raw = `{"RequestBody": "{\n		\\"CustomerType\\" : \\"${data.CustomerType}\\", \n  \\"IDCard\\" : \\"${data.IDCard}\\",\n  \\"Birthdate\\" : \\"${data.Birthdate}\\"\n	}", \n	"Path": "${this.$API_DEV_SKL}/contract_validate_account", \n	"Method": "POST"\n}`;
        var requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: raw,
          redirect: "follow"
        };
        fetch(`${this.$API_DEV_LINE_SKL}/Portal`, requestOptions)
          .then(response => response.json())
          .then(result => {
            if (result.result) {
              this.registerState.error = false;
              this.customerProfile = result.data;
              console.log(this.customerProfile);
              if (this.$OverrideMobileNumber)
                this.customerProfile.MobileNumber = this.$OverrideMobileNumber;

              var myHeaders = new Headers();
              myHeaders.append("Content-Type", "application/json");
              var raw = JSON.stringify({
                idCard: this.form.idCard,
          
                lineId: this.lineProfile.userId
              });

              var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow"
              };

              fetch(
                `${this.$API_DEV_LINE_SKL}/customer/validateregister`,
                requestOptions
              )
                .then(response => response.json())
                .then(result => {
                  this.buttonLoading = false;
                  if (result.result == 1) {
                    if (result.detail.isHaveLine) {
                      this.isHaveLine = true;
                      this.pageTitle = "ลงทะเบียนใช้บริการ";
                      this.state = "change-line";
                      this.displayName = result.detail.displayName;
                    } else {
                      this.state = "content-policy";
                      this.checkConsentRegister();
                      this.pageTitle =
                        "ข้อกำหนดและเงื่อนไข\nการใช้บริการ SKL Connect";
                    }
                  } else {
                    this.registerState.validateMessage = result.message;
                    this.registerState.error = true;
                    this.registerState.validateError = true;
                    this.failCount += 1;
                  }
                })
                .catch(error => {
                  this.registerState.error = true;
                  this.registerState.internetError = true;
                  console.error(error);
                  this.failCount += 1;
                  this.buttonLoading = false;
                  this.$bvModal.show("modal-tryagain");
                });
            } else {
              this.registerState.error = true;
              this.registerState.noData = true;
              this.failCount += 1;
              this.buttonLoading = false;
            }
          })
          .catch(error => {
            this.registerState.error = true;
            this.registerState.internetError = true;
            console.error(error);
            this.failCount += 1;
            this.buttonLoading = false;
            this.$bvModal.show("modal-tryagain");
          });
      } else {
        this.failCount += 1;
      }
    },
    sendOTP() {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        MobileNumber: `66${this.customerProfile.MobileNumber.slice(1)}`,
        LineId: this.lineProfile.userId,
        IdCard: this.customerProfile.IDCard
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow"
      };

      if (this.isHaveLine) {
        fetch(`${this.$API_DEV_LINE_SKL}/OTPChangeLine`, requestOptions)
          .then(response => response.json())
          .then(result => {
            this.otpState.prefix = result.detail.result;
          })
          .catch(error => {
            console.error(error);
          });
      } else {
        fetch(`${this.$API_DEV_LINE_SKL}/OTP`, requestOptions)
          .then(response => response.json())
          .then(result => {
            this.otpState.prefix = result.detail.result;
          })
          .catch(error => {
            console.error(error);
          });
      }
    },
    submitOTP() {
      this.buttonLoading = true;
      this.otpState.waiting = true;
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        MobileNumber: `66${this.customerProfile.MobileNumber.slice(1)}`,
        OTP: this.form.otpNumber,
        LineId: this.lineProfile.userId,
        IdCard: this.customerProfile.IDCard
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow"
      };
      if (this.isHaveLine) {
        fetch(`${this.$API_DEV_LINE_SKL}/ValidateOTPChangeLine`, requestOptions)
          .then(response => response.json())
          .then(result => {
            this.otpState.waiting = false;

            if (result.result == 1) {
              this.otpState.isError = false;
              this.otpState.otpMessage = "";
              this.checkConsentRegister();
              this.state = "content-policy";

              // var myHeaders = new Headers();
              this.buttonLoading = false;
              myHeaders.append("Content-Type", "application/json");
            } else {
              this.otpState.isError = true;
              this.otpState.otpMessage = result.message;
              this.failCount += 1;
              this.buttonLoading = false;
            }
          })
          .catch(error => {
            this.otpState.waiting = false;
            console.error(error);
            this.failCount += 1;
            this.buttonLoading = false;
            this.$bvModal.show("modal-tryagain");
          });
      } else {
        fetch(`${this.$API_DEV_LINE_SKL}/ValidateOTP`, requestOptions)
          .then(response => response.json())
          .then(result => {
            this.otpState.waiting = false;

            if (result.result == 1) {
              this.otpState.isError = false;
              this.otpState.otpMessage = "";
              var myHeaders = new Headers();
              myHeaders.append("Content-Type", "application/json");
              this.buttonLoading = false;
            } else {
              this.otpState.isError = true;
              this.otpState.otpMessage = result.message;
              this.failCount += 1;
              this.buttonLoading = false;
            }
          })
          .catch(error => {
            this.otpState.waiting = false;
            console.error(error);
            this.failCount += 1;
            this.buttonLoading = false;
            this.$bvModal.show("modal-tryagain");
          });
      }
    },

    policyonScroll() {
      var element = document.getElementById("policy");
      if (element.scrollHeight - element.scrollTop <= element.clientHeight) {
        this.policyState.showCheckBox = true;
      }
    },
    clickPolicyCheckBox() {
      if (this.policyState.showCheckBox) {
        this.policyState.seleced = !this.policyState.seleced;
      } else {
        this.$bvModal.show("comfirmPolicy");
      }
    },
    handleNewCustomer() {
      this.buttonLoading = true;
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        customerName: this.customerProfile.CustomerName,
        mobileNumber: this.customerProfile.MobileNumber,
        idCard: this.customerProfile.IDCard,
        birthDay: this.customerProfile.Birthdate,
        lineId: this.lineProfile.userId,
        createdBy: this.customerProfile.IDCard,
        type: this.form.typeAccount - 1,
        displayName: this.lineProfile.displayName,
        term: true
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        term: true,
        redirect: "follow"
      };
      fetch(`${this.$API_DEV_LINE_SKL}/customer/NewUser`, requestOptions)
        .then(response => response.json())
        .then(result => {
          if (result.result == 1) {
            this.buttonLoading = false;
            this.pageTitle = "ลงทะเบียนใช้บริการ";
            this.state = "content-result";
          }
        })
        .catch(error => {
          console.error(error);
          this.failCount += 1;
          this.buttonLoading = false;
          this.$bvModal.show("modal-tryagain");
        });
    },
    submitPolicy() {
      if (this.policyState.seleced && !this.isHaveLine) {
        this.handleNewCustomer();
      } else {
        this.pageTitle = "ลงทะเบียนใช้บริการ";
        this.state = "content-result";
      }
    },
    handleChangeLine(isChange) {
      if (isChange) {
        console.log(this.isHaveLine);
        this.state = "content-OTP";
        this.sendOTP();
      } else {
        this.state = "content-register";
        this.form.taxId = "";
        this.form.birthDay = "";
      }
    },
    formatTaxId(value) {
      let digits = value.replace(/\D/g, ""); // ลบอักขระที่ไม่ใช่ตัวเลข

      if (digits.length > 13) {
        digits = digits.slice(0, 13);
      }

      let formatted = "";

      if (digits.length > 0) formatted += digits.substring(0, 1);
      if (digits.length > 1) formatted += "-" + digits.substring(1, 5);
      if (digits.length > 5) formatted += "-" + digits.substring(5, 10);
      if (digits.length > 10) formatted += "-" + digits.substring(10, 12);
      if (digits.length > 12) formatted += "-" + digits.substring(12, 13);

      this.form.taxId = formatted;
    }
  }
};
</script>

<style scoped>
.fullHeight {
  height: 100vh !important;
}
.fullWidth {
  width: 100vh !important;
}
#content-register.hidden {
  display: none !important;
}
#content-OTP.hidden {
  display: none !important;
}
#content-policy.hidden {
  display: none !important;
}
#content-result.hidden {
  display: none !important;
}
#checkbox-empty.hidden {
  display: none !important;
}
#checkbox-check.hidden {
  display: none !important;
}
#result-success.hidden {
  display: none !important;
}
#result-fail.hidden {
  display: none !important;
}
#toggle-input-OTP {
  margin-top: 31px;
  margin-bottom: 10px;
}
#policy {
  /* width: 90%; */
  /* height: calc(100% - 90px); */
  overflow: auto;
  font-size: 14px;
}
#policy-submit-area {
  width: 90%;
}
#policy-submit-area.hidden {
  display: none !important;
}
.policy-bottom-area {
  position: fixed;
  bottom: 0;
  height: 100px;
  width: 100vw;
  background-color: white;
}
.btn-liff {
  width: 100%;
  min-width: 100px;
  height: 82px;
  border-radius: 16px;
  padding: 11px;
  border-style: solid;
  border-width: 1px;
}
.btn-liff-primary {
  color: #80c141 !important;
  border-color: #80c141 !important;
}
.btn-liff.selected {
  color: #fff !important;
  background: linear-gradient(
    0deg,
    rgb(71 143 78) 3%,
    rgb(125 181 36) 29%,
    rgb(168 213 110) 100%
  );
  border-color: #80c141 !important;
}
.svg-btn path {
  fill: #80c141;
}
.btn-liff.selected path {
  fill: #fff !important;
}
.date-area {
  position: relative;
}
.calendar-icon {
  position: absolute;
  right: 0;
  bottom: 0;
}
.date-picker {
  position: absolute;
  left: 0;
  bottom: 0;
  opacity: 0;
}
#input-OTP {
  margin: 10px;
}
.submit {
  margin-bottom: 30px;
  margin-left: 16px;
  margin-right: 16px;
  width: 188px;
  height: 48px;
  background-color: #80c141;
  border-radius: 35px;
}
#submit-finish.submit {
  background-color: #fff;
  border: 1px solid #bcbcbc;
}

.checkbox {
  margin-left: 12px;
  margin-right: 12px;
}

.disable {
  filter: grayscale(100%);
}
.checkbox-area {
  margin-top: 5px;
  margin-bottom: 5px;
}

#user-profile {
  border-radius: 100%;
}
.user-profile-result {
  position: relative;
  width: 92px;
  height: 92px;
}
#right-check {
  position: absolute;
  top: 0;
  right: 0;
}

.panel-bg-profile-img {
  width: 92px;
  height: 92px;
  background-color: #f3f3f3;
  margin: auto;
  border-radius: 50%;
  position: relative;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
}

.w-100.submit-policy {
  width: 100% !important;
}
.content_center {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
}
.input-text {
  width: auto !important;
}

.sub-container {
  height: calc(100vh - 100px);
}

.main-container {
  /* height: calc(100vh - 100px); */
  width: calc(100vw + 10px);
  border: 5px rgb(72, 180, 72) solid;
  border-bottom: none;
  border-top-left-radius: 50px;
  border-top-right-radius: 50px;
}

.link-tel {
  text-decoration-line: none;
  text-align: center;
  /* color: #212529; */
  color: #f48139;
  font-weight: bold;
}
.link-tel-green {
  color: #80c141;
}

.txt-tel {
  background-color: white;
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  padding: 4px;
}

.img-header-profile {
  border-radius: 50%;
  height: 100px;
  width: 100px;
}

.register-box {
  min-width: 350px;
}

.custom-width {
  overflow-x: hidden; /* ซ่อน border ซ้าย-ขวา */
}
</style>
