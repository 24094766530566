<template>
  <div class="form-group">
    <label class="text-label">
      {{ textFloat }} 
      <span v-if="isRequired" class="text-danger">*</span>
    </label>
    <div class="d-flex">
      <b-form-select
        v-model="date.day"
        :options="list.day"
        valueField="value"
        textField="text"
        class="mr-2"
      ></b-form-select>

      <b-form-select
        v-model="date.month"
        @change="updateMonth(date.month)"
        valueField="value"
        textField="text"
        :options="list.month"
        class="mr-2"
      ></b-form-select>

      <b-form-select
        v-model="date.year"
        :options="list.year"
        valueField="value"
        textField="text"
        @change="updateYear()"
      ></b-form-select>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    textFloat: {
      type: String,
      required: false
    },
    isRequired: {
      type: Boolean,
      required: false
    },
    value: { required: false }
  },
  data: () => ({
    date: {
      day: 0,
      month: 0,
      year: 0
    },
    dayDefault: [
      { value: 0, text: "วัน" },
      { value: 1, text: "01" },
      { value: 2, text: "02" },
      { value: 3, text: "03" },
      { value: 4, text: "04" },
      { value: 5, text: "05" },
      { value: 6, text: "06" },
      { value: 7, text: "07" },
      { value: 8, text: "08" },
      { value: 9, text: "09" },
      { value: 10, text: "10" },
      { value: 11, text: "11" },
      { value: 12, text: "12" },
      { value: 13, text: "13" },
      { value: 14, text: "14" },
      { value: 15, text: "15" },
      { value: 16, text: "16" },
      { value: 17, text: "17" },
      { value: 18, text: "18" },
      { value: 19, text: "19" },
      { value: 20, text: "20" },
      { value: 21, text: "21" },
      { value: 22, text: "22" },
      { value: 23, text: "23" },
      { value: 24, text: "24" },
      { value: 25, text: "25" },
      { value: 26, text: "26" },
      { value: 27, text: "27" },
      { value: 28, text: "28" },
      { value: 29, text: "29" },
      { value: 30, text: "30" },
      { value: 31, text: "31" }
    ],
    list: {
      day: [],
      month: [
        { value: 0, text: "เดือน", days: 0 },
        { value: 1, text: "มกราคม", days: 31 },
        { value: 2, text: "กุมภาพันธ์", days: 29 },
        { value: 3, text: "มีนาคม", days: 31 },
        { value: 4, text: "เมษายน", days: 30 },
        { value: 5, text: "พฤษภาคม", days: 31 },
        { value: 6, text: "มิถุนายน", days: 30 },
        { value: 7, text: "กรกฎาคม", days: 31 },
        { value: 8, text: "สิงหาคม", days: 31 },
        { value: 9, text: "กันยายน", days: 30 },
        { value: 10, text: "ตุลาคม", days: 31 },
        { value: 11, text: "พฤศจิกายน", days: 30 },
        { value: 12, text: "ธันวาคม", days: 31 }
      ],
      year: [{ value: 0, text: "ปี" }]
    },
    dateFormat: ""
  }),
  created() {
    this.list.day = [...this.dayDefault];
    let year = parseInt(this.$moment(new Date()).format("YYYY"));
    for (var i = year; i > year - 100; i--) {
      this.list.year.push({ value: i, text: i + 543 });
    }
  },
  watch: {
    date: {
      deep: true,
      handler: function () {
        if (this.date.day > 0 && this.date.month > 0 && this.date.year > 0) {
          this.formatDate();
          this.$emit("handleSelect", this.dateFormat);
        }
      }
    }
  },
  methods: {
    updateMonth(value) {
      const days = this.list.month.find(x => x.value == value).days;
      if (value === 2) {
        if (this.date.year % 4 === 0) {
          this.list.day = this.dayDefault.filter(x => x.value <= 29);
        } else this.list.day = this.dayDefault.filter(x => x.value <= 28);
      } else this.list.day = this.dayDefault.filter(x => x.value <= days);

      if (days < this.date.day) this.date.day = 0;
    },
    updateYear() {
      this.updateMonth(this.date.month);
    },
    formatDate() {
      const { day, month, year } = this.date;
      this.dateFormat = this.$moment(`${year}-${month}-${day}`).format(
        "YYYY-MM-DD"
      );
    }
  }
};
</script>
<style scoped>
.text-label {
  color: #16274a;
}

.text-desc {
  color: rgba(22, 39, 74, 0.4);
  font-size: 12px;
  font-weight: normal;
}
</style>
